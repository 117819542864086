import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { QueryClient, QueryClientProvider } from 'react-query';
const queryClient = new QueryClient();
ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <GoogleReCaptchaProvider
      reCaptchaKey="6LflDBUqAAAAAM2men7ksykyyR8euV7YqJo5EO0F"
    >
      <App />
    </GoogleReCaptchaProvider>
  </QueryClientProvider>,
  document.getElementById('root')
);
