import React, { useState } from 'react';
import { BASE_URL } from './config';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

function ContactForm() {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [age, setAge] = useState('');
    const [gender, setGender] = useState('');
    const [illness, setIllness] = useState('');
    const [isChecked, setIsChecked] = useState(false);
    const [captchaToken, setCaptchaToken] = useState('');

    const { executeRecaptcha } = useGoogleReCaptcha();

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!firstName || !lastName || !email || !phone || !age || !gender || !illness || !isChecked) {
            alert('Please fill all fields and agree to the terms.');
            return;
        }

        if (phone.trim().length !== 10) {
            alert('Phone number must be 10 digits.');
            return;
        }

        if (executeRecaptcha) {
            const token = await executeRecaptcha('submit');
            setCaptchaToken(token);
        } else {
            alert('reCAPTCHA failed to load.');
            return;
        }

        const data = { first_name: firstName, last_name: lastName, email, phone, age, gender, illness, token: captchaToken };

        const response = await fetch(BASE_URL + '/api/v1/contactus/addContact', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });

        const result = await response.json();

        if (response.status == 200) {
            alert('Form submitted successfully!');
            setFirstName('');
            setLastName('');
            setEmail('');
            setPhone('');
            setAge('');
            setGender('');
            setIllness('');
            setIsChecked(false);
        } else {
            alert('Failed to submit the form. Please try again.');
        }
    };

    return (
        <form onSubmit={handleSubmit} className="form px-5 md:px-0">
            <div className="md:w-[60%] mx-auto p-5 border rounded-lg">
                <div className="md:flex justify-between">
                    <div className="md:w-[48%]">
                        <label className="text-[#676d8a] font-semibold my-[10px]">
                            First Name<span className="text-red-700">*</span>
                        </label>
                        <input
                            type="text"
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                            className="my-4 block w-full px-3 py-2 border border-gray-300 shadow-sm focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50 rounded-full"
                        />
                    </div>
                    <div className="md:w-[48%]">
                        <label className="text-[#676d8a] font-semibold my-[10px]">
                            Last Name<span className="text-red-700">*</span>
                        </label>
                        <input
                            type="text"
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                            className="my-4 block w-full px-3 py-2 border border-gray-300 shadow-sm focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50 rounded-full"
                        />
                    </div>
                </div>
                <div className="md:flex justify-between">
                    <div className="md:w-[48%]">
                        <label className="text-[#676d8a] font-semibold my-[10px]">
                            Gender<span className="text-red-700">*</span>
                        </label>
                        <input
                            type="text"
                            value={gender}
                            onChange={(e) => setGender(e.target.value)}
                            className="my-4 block w-full px-3 py-2 border border-gray-300 shadow-sm focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50 rounded-full"
                        />
                    </div>
                    <div className="md:w-[48%]">
                        <label className="text-[#676d8a] font-semibold my-[10px]">
                            Age<span className="text-red-700">*</span>
                        </label>
                        <input
                            type="number"
                            value={age}
                            onChange={(e) => setAge(e.target.value)}
                            className="my-4 block w-full px-3 py-2 border border-gray-300 shadow-sm focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50 rounded-full"
                        />
                    </div>
                </div>
                <div>
                    <label className="text-[#676d8a] font-semibold my-[10px]">
                        Illness if any<span className="text-red-700">*</span>
                    </label>
                    <input
                        type="text"
                        value={illness}
                        onChange={(e) => setIllness(e.target.value)}
                        className="my-4 block w-full px-3 py-2 border border-gray-300 shadow-sm focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50 rounded-full"
                    />
                </div>
                <div className="md:flex justify-between">
                    <div className="md:w-[48%]">
                        <label className="text-[#676d8a] font-semibold my-[10px]">
                            Email<span className="text-red-700">*</span>
                        </label>
                        <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className="my-4 block w-full px-3 py-2 border border-gray-300 shadow-sm focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50 rounded-full"
                        />
                    </div>
                    <div className="md:w-[48%]">
                        <label className="text-[#676d8a] font-semibold my-[10px]">
                            Phone No.<span className="text-red-700">*</span>
                        </label>
                        <input
                            type="text"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            className="my-4 block w-full px-3 py-2 border border-gray-300 shadow-sm focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50 rounded-full"
                        />
                    </div>
                </div>
                <div className="px-[10px]">
                    <p className="text-[#676d8a] font-semibold">Yogashray Sewayatan Consent</p>
                    <p className="text-[#676d8a] my-2">
                        We require your consent to share your profile data with Yogashray Sewayatan. This will help us serve you better e.g. by prepopulating data on forms from your profile as required.
                    </p>
                    <div className="flex justify-between">
                        <div className="w-[48%]">
                            <div className="px-[10px]">
                                <input
                                    type="checkbox"
                                    checked={isChecked}
                                    onChange={() => setIsChecked(!isChecked)}
                                />
                                <label className="ml-2 text-[#676d8a]">Yes, I agree</label>
                            </div>
                        </div>
                    </div>
                </div>
                {/* reCAPTCHA Widget */}
                <div className="mt-4 text-end">
                    {executeRecaptcha && <div className="recaptcha-container">
                        {/* The ReCAPTCHA widget will be automatically handled */}
                    </div>}
                </div>
                <div className="mt-4 text-end">
                    <button
                        type="submit"
                        className="bg-[#f7972f] text-white font-semibold px-[30px] py-[10px] hover:bg-[#ea7e09]"
                    >
                        Submit
                    </button>
                </div>
            </div>
        </form>
    );
}

export default ContactForm;
